body, html {
  padding: 0;
  margin: 0;
  font-family: 'Dosis', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  outline-color: #2f8729 !important;

  &:focus-visible {
    outline: 4px solid #2f8729 !important;
  }
}

.MuiTouchRipple-root {
  display: none !important;
}

.MuiListItem-root button:hover {
  background: none !important;
}

#CybotCookiebotDialog {
  max-width: 350px !important;
  position: fixed !important;
  right: 51px !important;
  bottom: 45px !important;
  top: unset !important;
  left: unset !important;
  transform: unset !important;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 20%) !important;
}

@media screen and (max-width: 600px) {
  #CybotCookiebotDialog {
    bottom: 0 !important;
    right: 0 !important;
  }
}

#CybotCookiebotDialogBodyContentText {
  display: none !important;
}

#CybotCookiebotDialogBody {
  padding: 33px 35px !important;
}

#CybotCookiebotDialogPoweredbyLink {
  display: none;
}

#CybotCookiebotDialogBodyContent {
  padding: 0 !important;
}

#CybotCookiebotDialogBodyContent h2#CybotCookiebotDialogBodyContentTitle {
  font-family: 'Dosis', sans-serif !important;
  font-weight: 700 !important;
  font-size: 36px !important;
  line-height: 1.2 !important;
  color: #272727 !important;
  padding: 0 0 22px !important;
}

#CybotCookiebotDialogBodyContent > #CybotCookiebotDialogBodyContentText {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #272727 !important;
  padding: 0 0 22px !important;

  max-height: 100px;
  overflow-y: scroll;
}

#CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonAcceptWrapper {
  float: unset !important;
}

#CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonAcceptWrapper a#CybotCookiebotDialogBodyLevelButtonAccept {
  display: block !important;
  padding: 7px !important;
  font-family: 'Dosis', sans-serif !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 1.26 !important;
  color: #FFFFFF !important;
  margin: 0 0 19px;
}

/* Some MUI fixes */
.css-dzlzvs-MuiTypography-root-MuiLink-root span,
.css-dzlzvs-MuiTypography-root-MuiLink-root svg {
  display: inline-block;
  vertical-align: top;
}

/* MUI mobile datepicker popup */
.MuiDialog-root .MuiPaper-root[role=dialog] .MuiCalendarPicker-root {
  width: 100% !important;
  background-color: white;
}
.MuiDialog-root .MuiPaper-root[role=dialog] .MuiDialogActions-root {
  display: none;
}
.MuiDialog-root .MuiPaper-root[role=dialog] .MuiDialogContent-root .MuiPickersToolbar-root {
  display: none;
}

/* MUI ClockPicker popup */
.MuiPopper-root[role=dialog] .MuiPaper-root .MuiCalendarOrClockPicker-root {
  background-color: white;
}

.loadingcontent {
  text-align: center;
  padding: 40px 0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2E842A;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2E842A transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

img {
  object-fit: cover;
}